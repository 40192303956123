import {ManualTasksContent} from '@/components/NBAConfigEditor/ManualTasksContent'
import {useManualTask} from '@/components/NBAConfigEditor/useManualTask'
import Modal from '@/shared-components/Modal/Modal'
import {useAppDispatch, useAppSelector} from '@/store'
import {setModal} from '@/store/slices/nbaManualTask'
import {openNBADrawer} from '@ReduxActions'
import {useState} from 'react'
import {useIntl} from 'react-intl'

export const ManualTasks: React.FC = () => {
  const {formatMessage} = useIntl()
  const [file, setFile] = useState<Array<string>>([])
  const {onProccessFile, isFileProccessing, fileProcessError} = useManualTask()
  const {isOpenModal} = useAppSelector(state => state.nbaManualTask)
  const dispatch = useAppDispatch()

  const {activeManualTask} = useAppSelector(state => state.nbaManualTask)
  const {
    idx,
    task: {taskName},
  } = activeManualTask

  function onClose() {
    dispatch(setModal(false))
    dispatch(
      openNBADrawer({
        selectedPage: 'nbaDetails',
        title: formatMessage({id: 'NBA_BUILDER.NBA_DETAILS_DRAWER_TITLE'}),
      })
    )
  }

  return (
    <Modal
      data-testid="nba-manual-task-modal"
      onClose={() => {
        console.log('closse inside')
      }}
      primaryButtonDisabled={isFileProccessing}
      onClickSecondaryButton={onClose}
      onClickPrimaryButton={() => {
        if (file.length > 0) {
          onProccessFile(file[0], () => {
            onClose()
          })
        }
      }}
      isOpen={isOpenModal}
      title={formatMessage({id: 'NBA_BUILDER.NBA_MANUAL_TASK_TITLE'}, {idx, taskName})}
      primaryButtonText={formatMessage({
        id: isFileProccessing
          ? 'NBA_BUILDER.NBA_MANUAL_TASK_PRIMARY_BUSY_BUTTON'
          : 'NBA_BUILDER.NBA_MANUAL_TASK_PRIMARY_BUTTON',
      })}
      content={
        <ManualTasksContent
          onResult={(result: Array<string>) => {
            setFile(result)
          }}
          error={fileProcessError}
        />
      }
      // primaryButtonDisabled={!newJourneyCopyConfig.name}
    />
  )
}
