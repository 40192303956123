import {CountrySelector} from '@/components/NBAEditor/components/CountrySelector'
import {downloadFileHandler} from '@/helpers/downloadFileHandler'
import {FeatureToggle, isFeatureEnabled} from '@/helpers/featureToggle'
import {generateExcelFile} from '@/helpers/generateExcelFile'
import {getErrorMessage} from '@/helpers/getErrorMessage'
import {getFormattedCurrentTimestamp} from '@/helpers/getFormattedCurrentTimestamp'
import {useCountries} from '@/hooks/useCountries'
import {PageHeader} from '@/shared-components/PageHeader'
import {useAppDispatch, useAppSelector} from '@/store'
import {NBAError} from '@/types/NBAError'
import {NBAStatus} from '@/types/NBAStatus'
import {GetReportDataResponse, GetReportResponse} from '@/types/NBAs'
import {openNBADrawer, resetNBAConfig, showNotification} from '@ReduxActions'
import {getRequiresInterventionReportUseCase} from '@UseCases'
import {useMutation} from '@tanstack/react-query'
// import {Search} from 'pepsico-ds'
import {useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {NBAsListTable, NBAsListTableHandler} from '../components/NBAsListTable'
import {PrimaryButton, SecondaryButton} from '../shared-components/Button'
import {PageContent} from './Template/components/PageContent'
import {refetchNBA, setCountry, setEditMode} from '@/store/slices/commonSlice'

export const NBAsList = () => {
  const {formatMessage} = useIntl()
  const dispatch = useAppDispatch()
  const isFetchNba = useAppSelector(state => state.common.refetchNbaList)
  const {pagination} = useAppSelector(state => state.nbaList)

  const {countries, country} = useCountries()

  const tableListTableRef = useRef<NBAsListTableHandler>(null)

  /** refetch nba */
  useEffect(() => {
    if (isFetchNba) {
      tableListTableRef.current?.reload()
      setTimeout(() => {
        dispatch(refetchNBA(false))
      }, 1000)
    }
  }, [isFetchNba, dispatch])

  const {mutate, isPending: isExporting} = useMutation({
    mutationKey: ['getReport'],
    mutationFn: getRequiresInterventionReportUseCase,
  })

  if (!country) {
    return null
  }

  const onClickDownloadCSV = () => {
    if (isExporting) return

    mutate(
      {},
      {
        onSuccess: result => {
          const fileName = `Next Best Action Tool - Require Intervention records - ${getFormattedCurrentTimestamp()}.xlsx`
          isFeatureEnabled(FeatureToggle.dataReports)
            ? generateExcelFile((result as GetReportDataResponse).content, fileName)
            : downloadFileHandler((result as GetReportResponse).path, fileName)
        },
        onError: error => {
          const isCustomError = (error as NBAError) instanceof NBAError
          const {title, message} = getErrorMessage(error)
          dispatch(
            showNotification({
              show: true,
              type: isCustomError ? 'warning' : 'error',
              title,
              message,
            })
          )
        },
      }
    )
  }

  const onClickCreate = () => {
    dispatch(setEditMode(false))
    dispatch(
      openNBADrawer({
        selectedPage: 'nbaConfigEditor',
        title: formatMessage({id: 'NBA_BUILDER.CREATE_HEADER'}),
        canBeClosed: true,
      })
    )
    dispatch(resetNBAConfig())
  }

  const onCountryDidChange = (value: string) => {
    const newCountry = countries.find(c => c.code === value)
    newCountry && dispatch(setCountry(newCountry))
    tableListTableRef.current?.reload()
  }

  // DO not remove this code
  // const onNBANameSearch = (query: string) => {
  //   console.log(query)
  //   tableListTableRef.current?.reload()
  // }

  return (
    <PageContent
      data-testid="nbas-list-page"
      pageContentWrapperClassName="relative"
      pageHeaderContent={
        <div className="absolute -top-12 right-0 flex flex-row items-center gap-2 text-xs font-normal">
          <p className="text-nowrap">{formatMessage({id: 'GENERAL.SELECT_MARKET'})}</p>
          <CountrySelector
            id="main-country"
            status={NBAStatus.draft}
            value={country.code}
            onValueChanged={onCountryDidChange}
            className="w-fit"
          />
        </div>
      }
    >
      <div className="mb-6">
        <div className="flex items-center justify-between">
          <PageHeader
            title={formatMessage(
              {id: 'NBA_LIST.TABLE_HEADER'},
              {
                count: pagination?.totalElements ? `${pagination?.totalElements} - ` : '',
              }
            )}
            className="relative justify-start"
          />
          {/* <div className="w-[220px]">
            <Search placeholderText="Search by NBA name" className="w-[220px]" onUpdate={onNBANameSearch} />
          </div> */}
        </div>

        <div className="mt-2 flex items-start gap-4 text-base" data-testid="nbalist-subtitle-content">
          <div className="subtitle flex-1 text-sm text-text-secondary">
            {formatMessage({id: 'NBA_LIST.TABLE_HEADER_SUBTITLE'})}
          </div>

          <div className="button-container ml-auto flex gap-4">
            {/* {isFeatureEnabled(FeatureToggle.stepRequiresIntervention) && ( */}
            <SecondaryButton
              size="small"
              className="ml-auto hidden"
              data-testid="button-download-report"
              data-cy="button-download-report"
              onClick={onClickDownloadCSV}
              isLoading={isExporting}
            >
              {formatMessage({id: 'NBA_BUILDER.EXPORT_RECORDS_BUTTON'})}
            </SecondaryButton>
            {/* )} */}
            <PrimaryButton
              iconTrailing="playlist_add"
              size="small"
              data-cy="button-create-nba"
              data-testid="button-new-nba"
              onClick={onClickCreate}
            >
              {formatMessage({id: 'NBA_LIST.CREATE_BUTTON'})}
            </PrimaryButton>
          </div>
        </div>
      </div>
      <NBAsListTable ref={tableListTableRef} />
    </PageContent>
  )
}
export default NBAsList
