import {useNBAMetaConfig} from '@/hooks/useNBAMetaConfig'
import {useAppDispatch, useAppSelector} from '@/store'
import {proccessFileUseCase} from '@/useCases/proccessFileUseCase'
import {showNotification} from '@ReduxActions'
import {useMutation} from '@tanstack/react-query'
import {useState} from 'react'

export const useManualTask = () => {
  const {id: nbaId} = useNBAMetaConfig()
  const dispatch = useAppDispatch()
  const {activeManualTask} = useAppSelector(state => state.nbaManualTask)
  const [fileProcessError, setFileProcessError] = useState<string>('')
  const {mutate, isPending: isFileProccessing} = useMutation({
    mutationKey: ['proccessFile'],
    mutationFn: proccessFileUseCase,
    // mutationFn: createNBAUseCase,
    onSuccess: data => {
      setFileProcessError('')
      console.log('Notification created successfully:', data)
    },
    onError: error => {
      setFileProcessError(error.message)
      dispatch(
        showNotification({
          show: true,
          type: 'error',
          title: 'File Proccess',
          message: error.message,
        })
      )
      console.error('Error creating notification:', error)
    },
  })

  const onProccessFile = (file: string, onCallback: () => void) => {
    if (nbaId) {
      mutate(
        {nbaId, filePath: file, taskId: String(activeManualTask.task.taskId)},
        {
          onSuccess: res => {
            if (res.success) {
              onCallback()
            }
          },
        }
      )
    }
    console.log(nbaId)
  }

  return {
    isFileProccessing,
    fileProcessError,
    onProccessFile,
  }
}
