import NBADrawer from '@/components/Drawer/NBADrawer'
import {ManualTasks} from '@/components/NBAConfigEditor/ManualTasks'
import {PageLeavingWarning} from '@/components/PageLeavingWarningPrompt'
import {DEFAULT_INTL, ROUTES} from '@/config/constants'
import {useAuth} from '@/hooks/useAuth'
import {useMain} from '@/hooks/useMain'
import useInternationalization from '@/internationalization/useInternationalization'
import {NBAsList} from '@/pages/NBAsList'
import {useAppDispatch, useAppSelector} from '@/store'
import {setStayPage} from '@/store/slices/commonSlice'
import {openNBADrawer} from '@ReduxActions'
import {useEffect} from 'react'
import {IntlProvider, useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'

export const Home = () => {
  const {formatMessage} = useIntl()
  const {
    claims: {firstName},
  } = useAuth()

  const navigate = useNavigate()
  const {pathname} = useLocation()

  const canCancelPage = useAppSelector(state => state.common.canCancelPage)
  const dispatch = useAppDispatch()

  useMain()

  useEffect(() => {
    pathname === ROUTES.HOME && navigate(ROUTES.LIST_NBAS)
  }, [navigate, pathname])

  return (
    <div className="flex flex-col" data-testid="home-component">
      <p className="mb-4 text-3xl font-bold" data-cy="welcome-message">
        {formatMessage(
          {id: 'NBA_LIST.WELCOME_MESSAGE'},
          {
            userName: firstName || 'User',
          }
        )}
      </p>
      <NBAsList />
      <NBADrawer />
      <ManualTasks />
      <PageLeavingWarning
        shouldBlock={canCancelPage}
        onClose={() => dispatch(setStayPage())}
        onClickSecondaryButton={() => {
          dispatch(
            openNBADrawer({
              selectedPage: 'nbaConfigEditor',
              title: formatMessage({id: 'NBA_BUILDER.CREATE_HEADER'}),
              canBeClosed: true,
            })
          )
          dispatch(setStayPage())
        }}
        onClickPrimaryButton={() => {
          dispatch(setStayPage())
        }}
      />
    </div>
  )
}

function FederateHome() {
  const [language, message] = useInternationalization(DEFAULT_INTL)

  return (
    <IntlProvider locale={language} messages={message}>
      <Home />
    </IntlProvider>
  )
}

export default FederateHome
