import {proccessManualFiles} from '@/services/nbasService'
import {ProccessFileRequest, ProccessFileResponse} from '@/types/upload'
import {AxiosError} from 'axios'

export async function proccessFileUseCase(params: ProccessFileRequest): Promise<ProccessFileResponse> {
  try {
    const response = await proccessManualFiles(params)
    return response
  } catch (err) {
    const error = {
      message:
        (err instanceof AxiosError && err.response?.data.errorMessage) ||
        (err as Error).message ||
        'An unknown error occurred',
    }
    throw error
  }
}
