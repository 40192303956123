import {useFileUpload} from '@/hooks/useFileUpload'
import {useAppSelector} from '@/store'
import {NBAFileUploadType} from '@/types/NBAFileUploadType'
import {File} from 'pepsico-ds'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'

type ManualTasksContent = {
  onResult: (file: Array<string>) => void
  error?: string
}

let globalTaskId: string

export const ManualTasksContent = ({onResult, error}: ManualTasksContent) => {
  const {formatMessage} = useIntl()
  const [fileInputKey, setFileInputKey] = useState(Date.now())

  const content = formatMessage({id: 'NBA_BUILDER.MANUAL_TASKS_CONTENT'})
  const [file, setFile] = useState<Array<string>>([])
  const {isUpdating, onUploadFile, progress, fileUploadError} = useFileUpload()
  const {activeManualTask} = useAppSelector(state => state.nbaManualTask)
  globalTaskId = activeManualTask.task.taskId as string

  useEffect(() => {
    onResult && onResult(file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  const onFileUpdate = (e: File) => {
    const type = NBAFileUploadType.RESULT
    onUploadFile(
      e,
      res => {
        setFile(prev => [...prev, res.filePath])
        setFileInputKey(Date.now())
      },
      {fileType: type, taskId: globalTaskId}
    )
  }
  const files = file.map(f => f.split('/').pop() as string)
  return (
    <>
      <p className="py-2 pb-5 text-center text-xs" dangerouslySetInnerHTML={{__html: content}} />
      <div className="flex flex-col items-center justify-center">
        <File
          key={fileInputKey} // Add a key to force re-render
          files={files}
          onUpdate={onFileUpdate}
          label={formatMessage({id: 'NBA_BUILDER.UPLOAD_REPORT_FILE_LABEL'})}
          className="my-4 w-full "
          required
          onDelete={index => {
            setFile(prev => prev.filter((_, i) => i !== index))
          }}
          progressPerc={progress}
          disabled={isUpdating}
          variant={isUpdating ? 'uploading' : 'edit'}
          accept=".csv"
          error={error || fileUploadError}
        />
      </div>
    </>
  )
}
