import Modal, {ModalProps} from '@/shared-components/Modal/Modal'
import {useCallback} from 'react'
import {useIntl} from 'react-intl'

export type PageLeavingWarningProps = Omit<ModalProps, 'isOpen' | 'mode' | 'title' | 'content'> & {
  shouldBlock: boolean
  title?: string
  content?: string
}

export const PageLeavingWarning = ({shouldBlock, ...props}: PageLeavingWarningProps) => {
  const {formatMessage} = useIntl()

  const modalProps = {
    title: props.title ?? formatMessage({id: `CANCEL_NBA_CHANGES.MODAL_TITLE`}),
    content: props.content ?? formatMessage({id: `CANCEL_NBA_CHANGES.MODAL_CONTENT`}),
    primaryButtonText: props.primaryButtonText ?? formatMessage({id: `CANCEL_NBA_CHANGES.MODAL_SECONDARY_BUTTON`}),
    secondaryButtonText: props.secondaryButtonText ?? formatMessage({id: `CANCEL_NBA_CHANGES.MODAL_PRIMARY_BUTTON`}),
  }

  const onDidClose = useCallback(() => {
    props.onClose?.()
  }, [props])

  const onDidConfirm = useCallback(() => {
    props.onClickPrimaryButton?.()
  }, [props])

  const onDidCancel = useCallback(() => {
    props.onClickSecondaryButton?.()
  }, [props])

  return (
    <Modal
      {...modalProps}
      isOpen={shouldBlock}
      onClose={onDidClose}
      onClickPrimaryButton={onDidConfirm}
      onClickSecondaryButton={onDidCancel}
    />
  )
}
